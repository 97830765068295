import { Heading, Link, ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React from 'react';
import { Page } from '../components/Page';
import { seo } from '../components/Seo';
import { TopParagraphs } from '../components/TopParagraphs';

interface ReadingItem {
  label: string;
  url: string;
}
interface HistorySection {
  paragraphs: string[];
  readings: ReadingItem[];
}
interface Props {
  data: {
    branchJson: {
      topParagraphs: string[];
      history: HistorySection;
    };
  };
}

export const Head = seo({ title: 'Branch' });

export default function BranchPage({
  data: {
    branchJson: { topParagraphs, history },
  },
}: Props) {
  return (
    <Page>
      <TopParagraphs title="Aotearoa New Zealand Branch" paragraphs={topParagraphs} enlargeFirst />

      <VStack spacing={4}>
        <Heading size="md">Our History</Heading>
        {history.paragraphs.map((item: string) => (
          <Text key={item}>{item}</Text>
        ))}
      </VStack>

      <VStack>
        <Heading size="md">Further Reading</Heading>
        <UnorderedList>
          {history.readings.map((item: ReadingItem) => (
            <ListItem key={item.label}>
              <Link href={item.url} isExternal>
                {item.label}
              </Link>
            </ListItem>
          ))}
        </UnorderedList>
      </VStack>
    </Page>
  );
}

export const pageQuery = graphql`
  query BranchPageData {
    branchJson {
      topParagraphs
      history {
        paragraphs
        readings {
          label
          url
        }
      }
    }
  }
`;
