import { Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { PageHeader } from '../PageHeader';

export interface TopParagraphsProps {
  title: string;
  subtitle?: string;
  enlargeFirst?: boolean;
  paragraphs: string[];
}

export function TopParagraphs({ title, subtitle, enlargeFirst, paragraphs }: TopParagraphsProps) {
  return (
    <VStack spacing={4}>
      <PageHeader title={title} subtitle={subtitle} />
      {paragraphs.map((item: string, index: number) => (
        <Text key={item} {...(index === 0 && enlargeFirst && { fontSize: '2xl' })}>
          {item}
        </Text>
      ))}
    </VStack>
  );
}
